import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/Login.vue'),
    meta: { title: 'AiGo' }
  },
  {
    path: '/home',
    component: () => import('@/views/Home.vue'),
    redirect: '/menu',
    children: [
      {
        path: '/menu',
        component: () => import('@/views/pages/Menu.vue'),
        meta: { title: '主页' }
      }, {
        path: '/prompts/example',
        component: () => import('@/views/pages/prompts/PromptPlayground.vue'),
        meta: { title: '提示应用示例' }
      }, {
        path: '/prompts/repository',
        component: () => import('@/views/pages/prompts/PromptAppRepository.vue'),
        meta: { title: '提示应用库' }
      }, {
        path: '/prompts/mine',
        component: () => import('@/views/pages/prompts/MyPromptApps.vue'),
        meta: { title: '我的提示应用' }
      }, {
        path: '/prompts/playground',
        component: () => import('@/views/pages/prompts/PromptPlayground.vue'),
        meta: { title: '提示工程' }
      }, {
        path: '/prompts/design',
        component: () => import('@/views/pages/prompts/DesignPromptApps.vue'),
        meta: { title: '提示应用设计' }
      }, {
        path: '/ai/chat',
        component: () => import('@/views/pages/ai/AIChat.vue'),
        meta: { title: 'AI交互' }
      }, {
        path: '/ai/survey',
        component: () => import('@/views/pages/ai/survey/Survey.vue'),
        meta: { title: 'AI问卷' }
      }, {
        path: '/toolbox/class-qs-analysis',
        component: () => import('@/views/pages/toolbox/class-analysis/ClassQsAnalysis.vue'),
        meta: {title: '课堂提问类型分析'}
      }, {
        path: '/management/user-management',
        component: () => import('@/views/pages/management/UserManagement.vue'),
        meta: {title: '账号管理'}
      }, {
        path: '/class-analysis-detail',
        component: () => import('@/views/pages/toolbox/class-analysis/Analysis.vue'),
        meta: {title: '课堂录像分享'}
      }
    ]
  },
  {
    path: '/gai-attitude-survey',
    component: () => import('@/views/pages/toolbox/GAIAttitudeSurvey.vue')
  }, {
    path: '/ai-survey',
    component: () => import('@/views/pages/ai/survey/SurveyTemplate.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router