/**
 * 维护一个messagebo列表，在入口文件中注册
 */

import { ElMessage } from 'element-plus'

const statusArr = {
  // 内容填写
  'SELECTION_NULL': {
    type: 'warning',
    message: '请选择内容'
  }, 
  'INPUT_NULL': {
    type: 'warning',
    message: '内容不能为空'
  },
  // 请求结果
  'REQUEST_SUCCESS': {
    type: 'success',
    message: '操作成功'
  },
  'REQUEST_ERROR': {
    type: 'warning',
    message: '有点儿问题，请稍后重试'
  },
  'SURVEY_END': {
    type: 'success',
    message: '访谈结束，感谢您的参与和分享，祝您生活愉快！',
    duration: 8000
  },
  // 文件格式校验
  'FILE_SIZE_EXCEED': {
    type: 'warning',
    message: '文件大小超出限制'
  },
  'FILE_TYPE_NOT_SUPPORTED': {
    type: 'warning',
    message: '文件格式不符合要求'
  },
  'FILE_NULL': {
    type: 'warning',
    message: '请上传文件'
  },
  // 网络请求等待
  'TASK_STATUS_ON': {
    type: 'success',
    message: '正在处理，请等待处理完成'
  },
  'ERROR_PROCESSING_FILE': {
    type: 'warning',
    message: '文件处理失败，请重试'
  },
  'TASK_STATUS_COMPLETED': {
    type: 'success',
    message: '已完成'
  },
  'DEVELOPING': {
    type: 'warning',
    message: '正在开发'
  },
  // 提示但不阻止
  'VALID_NULL_CONTENT': {
    type: 'info',
    message: '没有填写或选择任何内容'
  }
}

export default function showMsgBox(status) {
  ElMessage({
    type: statusArr[status].type,
    message: statusArr[status].message,
    grouping: true,
    duration: statusArr[status].duration ? statusArr[status].duration : 3000
  })
}