import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn'
import 'font-awesome/css/font-awesome.min.css'
import { pdate, getUUID, copyContent, text2Doc } from '@/utils/toolsUtil.js'

// highlight 的样式，依赖包，组件
// import 'highlight.js/styles/atom-one-dark.css'
// import 'highlight.js/lib/common'
// import hljs from '@highlightjs/vue-plugin'

import router from '@/router'

import showMsgBox from '@/utils/messageBoxUtil.js'



/**
 * 检查token是否过期
 */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  // let bearer = localStorage.getItem('bearer');
  // if (bearer) {
  //   if (JSON.parse(window.atob(bearer)).exp > new Date().getTime()) {
  //     if (to.path === '/login') {
  //       router.replace({ path: '/home' }); 
  //     }
  //     if (to.path === '/patterns/process' && !to.query.patternid) {
  //       router.replace({ path: '/home' });
  //     }
  //     return next();
  //   }
  // } else {
  //   return router.replace({ path: '/login' });
  // }
  next();
})


const app = createApp(App)

// 定义filter方法
app.config.globalProperties.$filters = {
  pdate: pdate,
  getUUID: getUUID,
  copyContent: copyContent,
  text2Doc: text2Doc
}

// 定义全局变量
app.config.globalProperties.$msg = showMsgBox

// app.use(router).use(ElementPlus, {locale}).use(hljs ).mount('#app')
app.use(router).use(ElementPlus, {locale}).mount('#app')